import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';

/**
 * @name InputField
 *
 * @desc InputField Component.
 * Renders a text field input for a form
 *
 * @component
 * @category Form Fields
 * @param {Object} props - JSON object of the form field properties
 * @return {React.ComponentType} InputField component JSX.
 * @example
 * <InputField name={field.name} label={field.label} />
 */
export default function InputField(props) {
    const { ...rest } = props;
    const [field, meta] = useField(props);

    function renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
        return '';
    }

    return (
        <TextField
            type="text"
            error={meta.touched && meta.error && true}
            helperText={renderHelperText()}
            {...field}
            {...rest}
        />
    );
}
