const stringifyArgs = (...args) => {
    const filteredArgs = args.filter((arg) => typeof arg !== 'function');
    const combinedMessage = filteredArgs
        .map((arg) => {
            if (typeof arg === 'object' && arg !== null) {
                return JSON.stringify(arg);
            }
            return arg;
        })
        .join(' ');
    return combinedMessage;
};

export default stringifyArgs;
