import axios from 'axios';

// TODO env vars
const BASE_URL = process.env.REACT_APP_ADDRESS_SERVICE_BASE_URL;
const AUTH_KEY = process.env.REACT_APP_ADDRESS_SERVICE_AUTH_KEY;

const apiService = axios.create({
    baseURL: BASE_URL,
});

export const addressLookup = async (query, cancel = false) => {
    const cancelToken = cancel ? new axios.CancelToken((cancelFn) => cancelFn('API canceled!')) : undefined;

    /**
     * @param {String} option
     * @returns String
     * function adapted from smarty docs to handle
     * option formatting. see below
     * https://www.smarty.com/docs/cloud/us-autocomplete-pro-api
     */
    const buildAddress = (item) => {
        const address = item
            ? `${item.street_line}${item.secondary ? ` ${item.secondary}` : ''}, ${
                  item.entries > 1 ? `(${item.entries} entries) ` : ''
              }${item.city}, ${item.state} ${item.zipcode}`
            : '';
        return { ...item, ...{ address } };
    };

    const streetSearch = (q) => {
        return apiService.get(`${BASE_URL}/lookup?key=${AUTH_KEY}&search=${q}`, { cancelToken });
    };

    // https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
    const unitSearch = (q) => {
        // 1905 Kramer Ln Ste, (8 entries) Austin, TX 78758
        const street = q.split(',')[0];
        const selected = q.replace(' entries', '');
        return apiService.get(`${BASE_URL}/lookup?key=${AUTH_KEY}&search=${street}&selected=${selected}`, { cancelToken });
    };

    try {
        const regexPattern = /\(\d+ entries\)/;
        const response = regexPattern.test(query) ? await unitSearch(query) : await streetSearch(query);

        return response.data.suggestions.map((address) => buildAddress(address));
    } catch (error) {
        if (axios.isCancel(error)) {
            // console.log('API request canceled:', error.message);
            // TODO decide if anything needs to be done here
        } else {
            throw Error('addressLookup :: Axios error ', error);
        }
        return null;
    }
};

// TODO
export const addressDetail = {};

export const isValidAddress = (address) => {
    // 1905 Kramer Ln Ste, Austin, TX 78758
    const addressPattern = /^[\d\s\w/]+,\s[\d\s\w]+,\s[A-Z]{2}\s\d{5}$/;

    // 1905 Kramer Ln Ste, (8 entries) Austin, TX 78758
    const entriesPattern = /\(\d+ entries\)/;

    return addressPattern.test(address) && !entriesPattern.test(address);
};
