import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'contexts/AuthProvider';
import { useLogging } from 'contexts/logging';

import Loader from 'components/loadable/Loader';

export default function AuthCheck({ children }) {
    const authContext = useAuth();
    const logging = useLogging();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // controls logic base on whether user is logged in or not

    useEffect(() => {
        async function checkAuthentication() {
            try {
                const user = await authContext.getUser();
                logging.log('checkAuthentication :: user :: ', user);

                if (user) {
                    setIsLoggedIn(true);
                } else {
                    // user is not logged. use auth library login flow
                    await authContext.login();
                }
            } catch (error) {
                logging.log('checkAuthentication :: error :: ', error);
            }
        }

        checkAuthentication();
    }, [authContext, isLoggedIn, logging]);

    if (isLoggedIn) return children;
    return <Loader />;
}

AuthCheck.propTypes = {
    children: PropTypes.node,
};
