import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { useLogging } from 'contexts/logging';

/**
 * @name FormWizard
 *
 * @desc FormWizard feature.
 * Setups and renders a Formik Form
 *
 * @feature
 * @category Common
 * @param {Object} formModel - JSON object of the form properties
 * @param {React.ComponentType} SubmitComponent - The Submit Component to be used when submitting the form
 * @return {React.ComponentType} FormWizard (Formik) component JSX.
 * @example
 * <FormWizard formModel={formModel} submitComponent={SubmitComponent} />
 */
export default function FormWizard({ formModel, SubmitComponent, initialData }) {
    const logging = useLogging();
    const { steps } = formModel;

    /* Initial form values can come from two places
      1. The form model
      2. The initialData prop
      If both are populated for the same field, initialData prop has higher priority
   */
    const formInitialValues = () => {
        let ret = {};
        steps.forEach((step) => {
            Object.keys(step.formField).forEach((fieldName) => {
                ret = { ...ret, [fieldName]: step.formField[fieldName].initial };
            });
        });
        return { ...ret, ...initialData };
    };

    const [activeStep, setActiveStep] = useState(formModel.steps[0]);
    const currentValidationSchema = activeStep.validationSchema;
    const isSubmissionStep = activeStep.name === formModel.submissionStep;
    const isLastStep = activeStep.order === steps.length - 1;

    function renderStepContent(step) {
        return React.cloneElement(step.render, { ...step });
    }

    const sleep = (delay) => (resolve) => setTimeout(resolve, delay);

    async function submitForm(values, actions) {
        try {
            await sleep(1000);
            const processedValues = (formModel.submissionPreprocess && formModel.submissionPreprocess(values)) || values;
            const response = await formModel.submissionAPI(processedValues);
            logging.log('post submit :: response ::', response);

            actions.setSubmitting(false);
            setActiveStep(steps[activeStep.order + 1]);
        } catch (error) {
            logging.error(error);
            actions.setSubmitting(false);
        }
    }

    function doSubmit(values, actions) {
        logging.log('doSubmit :: ', values, actions);
        if (isSubmissionStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(steps[activeStep.order + 1]);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function handleBack() {
        setActiveStep(steps[activeStep.order - 1]);
    }

    return (
        <Formik
            validationSchema={currentValidationSchema}
            onSubmit={(values, actions) => doSubmit(values, actions)}
            enableReinitialize
            initialValues={formInitialValues()}
            validateOnMount
        >
            {({ isSubmitting, isValid }) => {
                return (
                    <Form id={formModel.formId}>
                        {renderStepContent(activeStep)}
                        {!isLastStep && (
                            <>
                                {formModel.showBackButton && activeStep !== 0 && (
                                    <Button onClick={() => handleBack()}>Back</Button>
                                )}
                                <Box>
                                    {SubmitComponent ? (
                                        <SubmitComponent isValid={isValid} isSubmitting={isSubmitting} />
                                    ) : (
                                        <>
                                            <Button
                                                disabled={!isValid || isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                {isSubmissionStep ? 'Submit' : 'Next'}
                                            </Button>
                                            {isSubmitting && <CircularProgress size={24} />}
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}

FormWizard.propTypes = {
    formModel: PropTypes.instanceOf(Object).isRequired,
    SubmitComponent: PropTypes.func,
    initialData: PropTypes.instanceOf(Object),
};
