import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SHEPHERD_BASE_URL;

const apiService = axios.create({
    baseURL: BASE_URL,
});

export const postEntryForm = async (values) => {
    try {
        const response = await apiService.post(
            `${BASE_URL}/prequalification/prequalification/
        `,
            values,
        );

        return response;
    } catch (error) {
        if (axios.isCancel(error)) {
            // console.log('API request canceled:', error.message);
            // TODO this needs to be captured?
        } else {
            throw Error('postEntryForm :: Axios error ', error);
        }
        return null;
    }
};

export const getEntryForm = {};
