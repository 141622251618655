/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { stringifyArgs } from 'utils';

const LoggingContext = createContext();
const env = process.env.REACT_APP_ENVIRONMENT;

export function useLogging() {
    return useContext(LoggingContext);
}

export function LoggingProvider({ children }) {
    const log = (...args) => {
        env !== 'production'
            ? console.log(...args)
            : Sentry.addBreadcrumb({ message: stringifyArgs(...args), level: 'info', category: 'console', data: [...args] });
    };

    const error = (e) => {
        env !== 'production'
            ? console.error(e)
            : Sentry.captureException(e, { message: e.message, tags: { environment: env } });
    };

    const loggingContextValue = useMemo(
        () => ({
            log,
            error,
        }),
        [],
    );

    return <LoggingContext.Provider value={loggingContextValue}>{children}</LoggingContext.Provider>;
}

LoggingProvider.propTypes = {
    children: PropTypes.node,
};
