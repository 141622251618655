import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

/**
 * @name SelectField
 *
 * @desc SelectField Component.
 * Renders a select (dropdown) component for form fields
 *
 * @component
 * @category Form Fields
 * @param {Object} props - JSON object of the form field properties
 * @return {React.ComponentType} SelectField component JSX.
 * @example
 * <SelectField name={field.name} label={field.label} options={field.options} />
 */
function SelectField(props) {
    const { name, label, options, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;
    function renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
        return '';
    }

    return (
        <FormControl {...rest} error={isError}>
            <InputLabel>{label}</InputLabel>
            <Select {...field} {...props} name={name} value={selectedValue || ''}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {renderHelperText()}
        </FormControl>
    );
}

SelectField.propTypes = {
    options: PropTypes.instanceOf(Array),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export default SelectField;
