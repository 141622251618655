import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const LoaderWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    // targets child elements with immediate siblings
    '& > * + *': {
        marginTop: theme.spacing(2),
    },
}));

/**
 * @name Loader
 *
 * @desc Loader Component.
 * Renders a loader with a linear progress indicator.
 *
 * @component
 * @category Common
 * @return {React.ComponentType} Loader component JSX.
 * @example
 * <Suspense fallback={<Loader />}>
 */
export default function Loader() {
    return (
        <LoaderWrapper>
            <LinearProgress color="primary" />
        </LoaderWrapper>
    );
}
