const staticAssetsURL = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'production':
        case 'PRODUCTION':
        case 'prod':
        case 'PROD':
            return 'https://visio-static-assets-prod.s3.amazonaws.com/';

        case 'staging':
        case 'stage':
        case 'STAGING':
        case 'STAGE':
            return 'https://visio-static-assets-stage.s3.amazonaws.com/';

        default:
            return 'https://visio-static-assets-dev.s3.amazonaws.com/';
    }
};

export default staticAssetsURL;
