import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, TextField, Autocomplete } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
// import { addressLookup } from '@visiolending/react-base';
import { addressLookup, isValidAddress } from 'services/addressService';
import { useLogging } from 'contexts/logging';
import { at } from 'lodash';

const StyledAutocomplete = styled(Autocomplete)(() => ({
    // Hide the popup icon
    '& .MuiAutocomplete-endAdornment': {
        display: 'none',
    },
}));

/**
 * @name AddressLookupField
 *
 * @desc AddressLookupField Component.
 * Renders an Autocomplete field for address that is hooked into Smarty Streets
 *
 * @component
 * @category Form Fields
 * @param {Object} props - Form properties for the Address Lookup field
 * @return {React.ComponentType} AddressLookupField component JSX.
 * @example
 * <AddressLookupField name={field.name} label={field.label} />
 */
export default function AddressLookupField(props) {
    const { name, label, ...rest } = props;
    const logging = useLogging();
    const [value, setAddressValue] = useState(null); // field display value
    const [inputValue, setInputValue] = useState(''); // formik value
    const [isPopupOpen, setIsPopupOpen] = useState(false); // popup state
    const [options, setOptions] = useState([]); // returned address data
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
        return '';
    }

    const fetch = useMemo(
        () =>
            debounce(async (query) => {
                try {
                    logging.log('addressLookup :: query :: ', query);
                    const results = await addressLookup(query, false);

                    let newOptions = [];
                    if (value) {
                        newOptions = [value];
                    }
                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    logging.log('newOptions :: ', newOptions);
                    setOptions(newOptions);
                    setIsPopupOpen(true);
                } catch (error) {
                    setIsPopupOpen(false);
                    logging.error(error);
                }
            }, 400),
        [value, logging],
    );

    useEffect(() => {
        if (inputValue.length < 3) {
            setOptions(value ? [value] : []);
            return undefined;
        }

        // prevents a call if a valid address is already selected
        if (isValidAddress(inputValue)) return undefined;

        // Call the debounced function to initiate the fetch
        return fetch(inputValue);
    }, [value, inputValue, fetch]);

    const onInputChangeHandler = (newInputValue) => {
        setInputValue(newInputValue);
        setIsPopupOpen(false);
    };

    return (
        <StyledAutocomplete
            id="address-lookup-field"
            getOptionLabel={(option) => option.address}
            filterOptions={(x) => x}
            options={options}
            open={isPopupOpen}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText=""
            onChange={(_, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setAddressValue(newValue); // sets visible field value
                setValue(newValue); // sets Formik context value
            }}
            onInputChange={(_, newInputValue) => {
                onInputChangeHandler(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    helperText={renderHelperText()}
                    error={meta.touched && meta.error && true}
                    {...field}
                    {...params}
                    label={label}
                    name={name}
                    {...rest}
                    fullWidth
                />
            )}
            renderOption={(autoCompleteprops, option) => {
                return (
                    <li {...autoCompleteprops}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box key={option.address} component="span">
                                    {option.address}
                                </Box>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

AddressLookupField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
