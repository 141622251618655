import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Amplify, Auth } from 'aws-amplify';
import { useLogging } from 'contexts/logging';

// TODO this shouldn't be here
const authConfig = {
    Auth: {
        region: 'us-east-1',
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEB_CLIENT_ID,
    },
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['openid'],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL,
        responseType: ['token'],
        mandatorySignIn: false,
    },
};

Amplify.configure(authConfig);

export const AuthenticationContext = createContext();

export function useAuth() {
    return useContext(AuthenticationContext);
}

export function AuthProvider({ children }) {
    const logging = useLogging();
    const [user, setUser] = useState('');
    const [session, setSession] = useState('');
    const [customState] = useState('');

    const login = useCallback(
        async (provider) => {
            const customProvider = provider || process.env.REACT_APP_COGNITO_DEFAULT_PROVIDER; // should go away with new Cognito implementation
            try {
                const response = await Auth.federatedSignIn({ customProvider, customState });
                return response;
            } catch (error) {
                logging.log('login :: error :: ', error);
                return false;
            }
        },
        [customState, logging],
    );

    const getSession = useCallback(async () => {
        if (session) {
            return session;
        }

        try {
            const response = await Auth.currentSession();
            logging.log('getSession :: session ::', response);
            setSession(response);
            return response;
        } catch (error) {
            logging.log('getSession :: error :: ', error);
            return false;
        }
    }, [session, logging]);

    const getUser = useCallback(async () => {
        try {
            if (user) return user;

            const response = session || (await getSession());
            setUser(response.idToken.payload); // cognito specific
            return response.idToken.payload;
        } catch (error) {
            logging.log('getUser :: error :: ', error);
            return false;
        }
    }, [user, session, getSession, logging]);

    const authContextValue = useMemo(
        () => ({
            login,
            getUser,
            getSession,
        }),
        [login, getUser, getSession],
    );

    return <AuthenticationContext.Provider value={authContextValue}>{children}</AuthenticationContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
