import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormControl, RadioGroup, Radio, FormControlLabel, FormHelperText } from '@mui/material';

/**
 * @name RadioField
 *
 * @desc RadioField Component.
 * Renders a Radio button group form field
 *
 * @component
 * @category Form Fields
 * @param {Object} props - JSON object of the form field properties
 * @return {React.ComponentType} RadioField component JSX.
 * @example
 * <RadioField name={field.name} label={field.label} options={field.options} />
 */
function RadioField(props) {
    const { name, label, options, ...rest } = props;
    const [field, meta] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;
    function renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
        return '';
    }

    return (
        <FormControl {...rest} error={isError}>
            <RadioGroup {...field} {...props} name={name} row>
                {options.map((option) => (
                    <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                ))}
            </RadioGroup>
            {renderHelperText()}
        </FormControl>
    );
}

RadioField.propTypes = {
    options: PropTypes.instanceOf(Array),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default RadioField;
